/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { FC, useEffect, useState } from 'react';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import Login from 'pages/Login';
import Screensaver from 'pages/Screensaver';
import CheckIn from 'pages/CheckIn';
import Checkout from 'pages/Checkout';
import Payment from 'pages/Payment';
import Survey from 'pages/Survey';
import NotFound from 'pages/NotFound';
import SurveySuccessful from 'pages/SurveySuccessful';
import { useAuth } from 'hooks/AuthContext';
import Loading from 'features/Loading';
import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
import { AppVersion, Settings } from 'types';
import { useSettings } from 'hooks/SettingsContext';
import LoginQR from 'pages/LoginQR';
import BookingsPage from 'pages/Bookings';
import { useIdleTimer } from 'react-idle-timer';
import useWindowSize from 'hooks/useWindowSize';
import CheckinSuccessful from './pages/CheckinSuccessful';
import './index.scss';
import { useTranslation } from 'react-i18next';
import { Layout } from 'components/Layout';

const App: FC = () => {
    const { settings, setSettings } = useSettings();
    const [globalSettings, setGlobalSettings] = useState();
    const timeout = (settings?.config && settings?.config.timer * 1000) ?? 15_000;
    const promptBeforeIdle = (timeout - 5000) / 1000;
    const { isLoading, clinic } = useAuth();
    const { isMobile } = useWindowSize();
    const [remaining, setRemaining] = useState<number>(timeout);
    const [isIdle, updateIsIdle] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        if (!clinic) return undefined;
        const db = getFirestore();
        const docRef = doc(db, 'clinicSettings', clinic);
        const unsubSettings = onSnapshot(docRef, (snapshotSettings) => {
            const settingsData = snapshotSettings.data() as Settings;
            setSettings(settingsData);
        });
        return () => unsubSettings();
    }, [clinic, setSettings]);

    useEffect(() => {
        const db = getFirestore();
        const subscribeGlobalSettings = onSnapshot(
            doc(db, 'globalSettings', 'pageRefresh'),
            async (doc) => {
                const docData = doc.data();
                if (docData?.refreshPage) {
                    location.reload();
                }
            }
        );
        return () => subscribeGlobalSettings();
    }, [setGlobalSettings]);

    const onIdle = () => {
        updateIsIdle(true);
    };

    const onActive = () => {
        updateIsIdle(false);
        setRemaining(timeout);
    };
    const { getRemainingTime, activate } = useIdleTimer({
        onIdle,
        onActive,
        timeout,
        promptBeforeIdle,
    });
    useEffect(() => {
        if (isIdle && !isMobile) {
            if (window.location.pathname !== '/') {
                navigate('/');
            }
        }
    }, [isIdle]);

    useEffect(() => {
        const interval = setInterval(() => {
            setRemaining(Math.ceil(getRemainingTime() / 1000));
        }, 500);

        return () => {
            clearInterval(interval);
        };
    });

    const increaseTime = () => {
        activate();
    };
    useEffect(() => {
        const env = process.env.NODE_ENV;
        const db = getFirestore();
        const docRef = doc(db, 'appVersion', env);
        const unsubAppVersion = onSnapshot(docRef, (snapshotAppVersion) => {
            const eventVersion = snapshotAppVersion.data() as AppVersion;
            const versionString = sessionStorage.getItem('version');
            if (!versionString) {
                sessionStorage.setItem('version', JSON.stringify(eventVersion));
            } else {
                const storedVersion = JSON.parse(versionString) as AppVersion;
                if (eventVersion.releasedAt.seconds > storedVersion.releasedAt.seconds) {
                    sessionStorage.setItem('version', JSON.stringify(eventVersion));
                    window.location.reload();
                }
            }
        });
        return () => unsubAppVersion();
    }, []);

    return !isLoading ? (
        <div onClick={increaseTime}>
            {!isMobile &&
            remaining > 1 &&
            remaining < promptBeforeIdle &&
            window.location.pathname !== '/' &&
            window.location.pathname !== '/screensaver' &&
            window.location.pathname !== '/login' ? (
                <div className="modal">
                    <h5>
                        {t('screen_timeout_text_1')} {remaining} sek
                    </h5>
                </div>
            ) : null}
            <Routes>
                <Route path="/qr/:clinicId/:service/:appointmentId" element={<LoginQR />} />
                <Route path="/qr/:clinicId/:service" element={<LoginQR />} />
                <Route path="/login" element={<Login />} />
                {clinic ? (
                    <Route element={<Layout />}>
                        <Route path="/" element={<Screensaver />} />
                        <Route path="/screensaver" element={<Screensaver />} />
                        <Route path="/check-in" element={<CheckIn />} />
                        <Route path="/bookings" element={<BookingsPage />} />
                        <Route path="/checkout/:status" element={<Checkout />} />
                        <Route path="/payment" element={<Payment />} />
                        <Route
                            path="/checkin-successful/:appointmentId"
                            element={<CheckinSuccessful />}
                        />
                        <Route path="/survey/:appointmentId" element={<Survey />} />
                        <Route path="/survey-successful" element={<SurveySuccessful />} />
                        <Route path="*" element={<NotFound />} />
                    </Route>
                ) : (
                    <Route path="*" element={<Navigate to="/login" />} />
                )}
            </Routes>
        </div>
    ) : (
        <Loading loading={isLoading} />
    );
};

export default App;
