import React, { createContext, useContext, useState, FC, ReactElement } from 'react';

export type TimerReturnType = {
    startTimer: (seconds: number, callback: () => void) => void;
};

const TimerContext = createContext<TimerReturnType>({
    startTimer: () => null,
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useTimer = () => useContext(TimerContext);

type TimerProviderProps = {
    children: ReactElement | ReactElement[];
};

const TimerProvider: FC<TimerProviderProps> = ({ children }) => {
    const [timer, setTimer] = useState<NodeJS.Timeout>();

    const startTimer = (seconds: number, callback: () => void) => {
        if (timer) {
            clearTimeout(timer);
        }
        const timeout = setTimeout(() => {
            callback();
            if (timer) {
                clearTimeout(timer);
            }
        }, seconds * 1000);
        setTimer(timeout);
    };

    return <TimerContext.Provider value={{ startTimer }}>{children}</TimerContext.Provider>;
};

export { TimerProvider, useTimer };
