import { FC } from 'react';
import styles from './modal.module.scss';
import ExclamationTriangle from 'components/icons/ExclamationTriangle/exclamationTriangle';
import { useTranslation } from 'react-i18next';
import { Modal } from 'components/Modal';
import { Button } from 'components/Button';

interface Props {
    closePopup: (value: boolean) => void;
    errorMessage: string;
}

const ErrorModal: FC<Props> = ({ errorMessage, closePopup }) => {
    const { t } = useTranslation();
    return (
        <Modal>
            <div className={styles.header}>
                <ExclamationTriangle />
            </div>
            <div className={styles.body}>
                <h3>{errorMessage}</h3>
                {/* <p>{t('check_in_text')}</p> */}
            </div>
            <div className={styles.closeButtonContainer}>
                <Button onClick={() => closePopup(false)}>{t('close_button_text')}</Button>
            </div>
        </Modal>
    );
};

export default ErrorModal;
