/* eslint-disable no-console */
import { useEffect, FC } from 'react';
import Loading from 'features/Loading';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from 'hooks/AuthContext';
import { useSettings } from 'hooks/SettingsContext';
import { getAccessToken } from 'helpers/getToken';

const LoginQr: FC = () => {
    const { clinicId, service, appointmentId } = useParams();
    const { isLoading, setClinic } = useAuth();
    const navigate = useNavigate();
    const { settings } = useSettings();
    useEffect(() => {
        if (!settings) return;
        sessionStorage.setItem('wd_id', settings.webDocClientId);
        sessionStorage.setItem('wd_secret', settings.webDocClientSecret);
        getAccessToken()
            .then(() => console.log('success'))
            .catch((err) => console.log(err));
    }, [settings]);

    useEffect(() => {
        if (!clinicId) return;
        setClinic(clinicId);
    }, [clinicId, setClinic]);

    useEffect(() => {
        if (!settings) return;
        if (!clinicId || !service) {
            navigate('/login');
            return;
        }
        if (clinicId && service && !appointmentId) {
            navigate(`/${service}`);
            return;
        }
        if (clinicId && service && appointmentId) {
            navigate(`/${service}/${appointmentId}`);
            return;
        }
    }, [settings, navigate, appointmentId, service]);

    return <Loading loading={isLoading} />;
};

export default LoginQr;
