/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { axiosClient } from './webdocAxiosClient';

export const createInvoiceService = async (
    clinicId: string,
    visitId: string,
    totalPayment: string
): Promise<any> => {
    const body = {
        visitId,
        totalPayment,
        clinicId,
        baseURL: process.env.REACT_APP_API_URL,
    };

    try {
        const response = await axiosClient.post('/invoice/create', body);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            return error.response.data;
        }

        return { error: true, message: 'Unexpected error' };
    }
};

export const updateInvoiceService = async (
    clinicId: string,
    invoiceId: string,
    totalPayment: string
): Promise<any> => {
    const body = {
        invoiceId,
        totalPayment,
        clinicId,
        baseURL: process.env.REACT_APP_API_URL,
    };
    try {
        const response = await axiosClient.post('/invoice/update', body);

        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            return error.response.data;
        }

        return { error: true, message: 'Unexpected error' };
    }
};
