/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { axiosClient } from './axiosClient';

export const initiateKlarnaPayment = async (
    url: string,
    amount: number,
    description: string
): Promise<any> => {
    const body = JSON.stringify({
        url,
        amount,
        description,
        id: 'hyperstack_test',
    });
    const options = {
        headers: {
            'content-type': 'application/json',
        },
    };

    try {
        const response = await axiosClient.post('/klarna/payments', body, options);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return error;
        }
        return { error: 'error' };
    }
};
