/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useState } from 'react';
import { BookingType, CaregiverType } from 'types';
import styles from './booking.module.scss';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import maleAvatar from '../../assets/male-round.png';
import femaleAvatar from '../../assets/female-round.png';
import { getCaregiverService } from 'firebase-service';
import { ClockIcon } from 'components/icons/clockIcon';
import { MapPin } from 'components/icons/mapPin';

type Props = {
    clinicName: string | undefined;
    clinicId: string | undefined;
    booking: BookingType;
    selectBooking: (booking: BookingType) => void;
    active?: boolean;
    selected?: boolean;
    light?: boolean;
};

export const Booking: FC<Props> = ({
    clinicName,
    clinicId,
    booking,
    selectBooking,
    active,
    selected,
    light,
}) => {
    const { t } = useTranslation();
    const { firstName, lastName, title, identifier } = booking.caregiver;
    const [careGiver, setCareGiver] = useState<CaregiverType>();
    const id = identifier ?? booking.caregiver.personalNumber ?? '';
    // eslint-disable-next-line no-console
    useEffect(() => {
        if (!clinicId) return undefined;
        const getCaregiver = async () => {
            const _caregiver = await getCaregiverService(
                clinicId,
                identifier ?? booking.caregiver.personalNumber
            );
            setCareGiver(_caregiver as CaregiverType);
        };
        getCaregiver();
        return () => {
            getCaregiver();
        };
    }, []);
    const getAvatar = (perNumber: string) => {
        const fourDigits = perNumber.slice(9);
        if (+fourDigits[2] % 2 === 0) {
            return femaleAvatar;
        }
        return maleAvatar;
    };
    const getClassName = () => {
        if (active && !selected) {
            return styles.container_no_shadow;
        }
        if (active && selected) {
            return styles.container_shadow;
        }
        if (light) {
            return styles.light;
        }
        return styles.container_disabled;
    };

    return (
        <div className={getClassName()} onClick={active ? () => selectBooking(booking) : undefined}>
            <h3 className={styles.booking_title}>{t('booking_title')}</h3>
            <div className={styles.time_location_container}>
                <div className={selected ? styles.rounded_box_blue : styles.rounded_box}>
                    <ClockIcon color={light ? '#ffffff' : '#090909'} />
                    <p>{booking.startTime}, </p>
                    <p> {moment(booking.date).format('D MMM')}</p>
                </div>
                <div className={selected ? styles.rounded_box_blue : styles.rounded_box}>
                    <MapPin color={light ? '#ffffff' : '#090909'} />
                    <p>{clinicName}, </p>
                    {careGiver && (
                        <p>{`${careGiver.floor && t('floor') + careGiver.floor + ','}
                        ${careGiver.waitingRoom && t('waiting_room')}
                        ${careGiver.waitingRoom}`}</p>
                    )}
                </div>
            </div>
            <div className={styles.row_container}>
                <div className={styles.doctor_container}>
                    <div className={styles.avatar}>
                        <img src={getAvatar(id)} alt="Avatar" height={42} />
                    </div>
                    <div>
                        <p className={styles.name}>
                            {firstName} {lastName}
                        </p>
                        <p className={styles.title}>{title}</p>
                    </div>
                </div>
                {active && selected && (
                    <button className={styles.checkInButton}>{t('selected')}</button>
                )}
                {active && !selected && (
                    <button className={styles.selectButton}>{t('select')}</button>
                )}
            </div>
        </div>
    );
};
