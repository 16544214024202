import React, { FC } from 'react';
import styles from './successCard.module.scss';
import { CheckIcon } from 'components/icons/check';
import { BookingType, CaregiverType } from 'types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

interface PropsTypes {
    showButton?: boolean;
    transparent?: boolean;
    booking?: BookingType;
    caregiver?: CaregiverType;
}

export const SuccessCard: FC<PropsTypes> = ({ booking, caregiver, showButton, transparent }) => {
    const { t } = useTranslation();
    return (
        <div className={transparent ? styles.container_white_bg : styles.container}>
            <CheckIcon color={'#0FBA0C'} />
            <h2 className={styles.success_title}>{t('check_in_complete_header')}</h2>
            <p className={styles.success_text}>
                Tack för att du checkade in. Detaljer kring ditt besök finner du nedan
            </p>
            <div className={styles.divider} />
            <div className={styles.booking}>
                <div className={styles.booking_info}>
                    <p className={styles.info_title}>{t('date')}</p>
                    <p className={styles.info_text}>
                        {booking?.startTime}, {moment(booking?.date).format('D MMM')}
                    </p>
                </div>
                <div className={styles.booking_info}>
                    <p className={styles.info_title}>{t('place')}</p>
                    <p className={styles.info_text}>
                        {caregiver?.floor && t('floor') + ' ' + caregiver?.floor}{' '}
                        {caregiver?.waitingRoom && t('waiting_room') + ' ' + caregiver?.waitingRoom}
                    </p>
                </div>
                <div className={styles.booking_info}>
                    <p className={styles.info_title}>{t('you_will_meet')}</p>
                    <p className={styles.info_text}>
                        {booking && booking.caregiver.title}
                        {booking && booking.caregiver.firstName}{' '}
                        {booking && booking.caregiver.lastName}
                    </p>
                </div>
            </div>
            {showButton && (
                <div>
                    <button className={styles.button}>Visa mig vägen dit</button>
                </div>
            )}
        </div>
    );
};
