/* eslint-disable @typescript-eslint/no-explicit-any */
import { axiosClient } from './axiosClient';
import axios from 'axios';

export const getAccessTokenService = async (): Promise<any> => {
    const username = sessionStorage.getItem('wd_id');
    const password = sessionStorage.getItem('wd_secret');
    const url = process.env.REACT_APP_AUTH_URL;
    const body = {
        username,
        password,
        url,
    };
    try {
        const response = await axiosClient.post('/auth/token', body);

        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            return { error: true, message: error.response.data };
        }

        return { error: true, message: 'An unexpected error occurred' };
    }
};
