import React, { FC } from 'react';
import styles from './card.module.scss';

type Props = {
    sm?: boolean;
    children: React.ReactNode;
};
export const Card: FC<Props> = ({ sm, children }) => {
    const getClassName = () => {
        if (sm) {
            return styles.small_card;
        }
        return styles.card;
    };
    return <div className={getClassName()}>{children}</div>;
};
