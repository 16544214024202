import axios from 'axios';
import { getAuth } from 'firebase/auth';

const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});
axiosClient.interceptors.request.use(async (config) => {
    const token = await getAuth().currentUser?.getIdToken();
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
});
axiosClient.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        const maxLimit = 2;
        let count = 0;
        count++;
        if (count < maxLimit && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const token = await getAuth().currentUser?.getIdToken(true);
            if (token) {
                axios.defaults.headers.common.Authorization = 'Bearer ' + token;
                return axiosClient(originalRequest);
            }
        }
        return Promise.reject(error);
    }
);

export { axiosClient };
