/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { axiosClient } from './axiosClient';

type Data = {
    callbackUrl: string;
    payeeAlias: string;
    currency: string;
    amount: string;
};

export const createSwishPaymentService = async (data: Data): Promise<any> => {
    try {
        const response = await axiosClient.post('/swish/paymentrequest/new', data, {
            headers: {
                'content-type': 'application/json',
            },
        });

        return { status: response.status, data: response.data };
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            return { error: true, message: error.response.data };
        }

        return { error: true, message: 'An unexpected error occurred' };
    }
};

export const getSwishQRService = async (token: string): Promise<any> => {
    try {
        const response = await axiosClient.get(`/swish/swishqr/${token}`, {
            responseType: 'arraybuffer',
        });
        const arrayBufferView = new Uint8Array(response.data);
        const blob = new Blob([arrayBufferView], { type: 'image/jpeg' });
        const urlCreator = window.URL || window.webkitURL;
        return urlCreator.createObjectURL(blob);
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            return error.response.data;
        }

        return { error: true, message: 'Unexpected error' };
    }
};
