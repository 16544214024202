import CryptoJS from 'crypto-js';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const encrypt = (message) => {
    // eslint-disable-next-line no-undef
    const secretKey = process.env.REACT_APP_SECRET_KEY;
    if (!secretKey) return null;
    const keyutf = CryptoJS.enc.Utf8.parse(secretKey);
    const iv = CryptoJS.enc.Base64.parse(secretKey);
    const enc = CryptoJS.AES.encrypt(message, keyutf, { iv: iv });
    return enc.toString();
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const decrypt = (ciphertext) => {
    // eslint-disable-next-line no-undef
    const secretKey = process.env.REACT_APP_SECRET_KEY;
    if (!secretKey) return null;
    const keyutf = CryptoJS.enc.Utf8.parse(secretKey);
    const iv = CryptoJS.enc.Base64.parse(secretKey);
    const dec = CryptoJS.AES.decrypt(
        { ciphertext: CryptoJS.enc.Base64.parse(ciphertext) },
        keyutf,
        {
            iv: iv,
        }
    );
    const decStr = CryptoJS.enc.Utf8.stringify(dec);
    return decStr;
};
