import { FC, ReactNode } from 'react';
import styles from './wrapper.module.scss';

type Props = {
    gradient?: boolean;
    children: ReactNode;
};

export const Wrapper: FC<Props> = (props) => {
    return (
        <div className={props.gradient ? styles.container_gradient_bg : styles.container}>
            {props.children}
        </div>
    );
};
