import moment from 'moment';
import { BookingType } from 'types';

export const getFilteredBookings = (allBookings: BookingType[], margin: number) => {
    const today = moment().format('YYYY-MM-DD');
    const now = new Date();
    const currentTime = moment(now).subtract(margin, 'minutes').format('HH:mm');
    const todayBookings = allBookings?.filter((booking) => booking.date === today);
    const checkInAbleBookings =
        todayBookings &&
        todayBookings
            .filter((booking) => booking.startTime >= currentTime)
            .sort((a, b) => {
                if (a.startTime > b.startTime) {
                    return 1;
                }
                if (a.startTime < b.startTime) {
                    return -1;
                }
                return 0;
            });

    const upComingBookings = allBookings
        .filter((booking) => booking.date > today)
        .sort((a, b) => {
            if (a.startTime > b.startTime) {
                return 1;
            }
            if (a.startTime < b.startTime) {
                return -1;
            }
            return 0;
        })
        .filter((booking, i) => i < 1);
    const bookings = {
        todayBookings,
        checkInAbleBookings,
        upComingBookings,
    };
    return bookings;
};
