/* eslint-disable @typescript-eslint/no-explicit-any */
import { getAccessTokenService } from 'services/token.service';

export const getAccessToken = async (): Promise<any> => {
    const response = await getAccessTokenService();
    if (response.access_token) {
        sessionStorage.setItem('token', response.access_token);
        return response;
    }
    return response;
};
