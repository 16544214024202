import { FC, ReactNode } from 'react';
import styles from './modal.module.scss';

type Props = {
    children: ReactNode;
};

export const Modal: FC<Props> = ({ children }) => {
    return (
        <>
            <div className={styles.bg} />
            <div className={styles.modal}>
                <div className={styles.modalContent}>{children}</div>
            </div>
        </>
    );
};
