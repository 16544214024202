import axios from 'axios';
import { getAccessToken } from '../helpers/getToken';

const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        'content-type': 'application/json',
    },
});

axiosClient.interceptors.request.use((config) => {
    const token = sessionStorage.getItem('token');
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
});
axiosClient.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        const maxLimit = 2;
        let count = 0;
        count++;
        if (count < maxLimit && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const res = await getAccessToken();
            if (res.access_token) {
                axios.defaults.headers.common.Authorization = 'Bearer ' + res.access_token;
                return axiosClient(originalRequest);
            }
        }
        return Promise.reject(error);
    }
);
export { axiosClient };
