/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from 'react';
import SurveyInputForm from 'features/SurveyInputForm';
import { useTranslation } from 'react-i18next';
import styles from './survey.module.scss';
import { useSettings } from 'hooks/SettingsContext';
import { doc, getFirestore, onSnapshot, getDoc, collection } from '@firebase/firestore';
import { useNavigate, useParams } from 'react-router';
// import { updateVisitService } from 'services/visit.service';
import { FormData, ScreeningFields } from 'types';
import { Wrapper } from 'components/Wrapper';
import Loading from 'features/Loading';
import { updateFirestoreAppointment } from 'firebase-service';
import { useAuth } from 'hooks/AuthContext';

const Survey: FC = () => {
    const { clinic } = useAuth();
    const [formData, setFormData] = useState<FormData>({});
    const [appointment, updateAppointment] = useState<any>();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { settings } = useSettings();
    const { appointmentId } = useParams() as never;
    const [isLoading, updateLoading] = useState(false);
    const [isChecked, updateIsChecked] = useState(false);

    const db = getFirestore();

    useEffect(() => {
        if (!clinic) return undefined;
        updateLoading(true);
        const getAppointment = async () => {
            const docRef = doc(
                collection(db, 'clinicSettings', clinic, 'appointments'),
                appointmentId
            );
            const docSnap = await getDoc(docRef);
            const data = docSnap.data();
            if (data && 'screening' in data) {
                setFormData(data.screening);
            }
            updateLoading(false);
        };
        getAppointment();
        return () => {
            getAppointment();
        };
    }, []);
    useEffect(() => {
        if (!clinic) return undefined;
        const subscribe = onSnapshot(
            doc(collection(db, 'clinicSettings', clinic, 'appointments'), appointmentId),
            (doc) => {
                const appointmentData = doc.data();
                if (appointmentData) {
                    updateAppointment(appointmentData);
                }
            }
        );

        return () => subscribe();
    }, []);
    const updateFirebaseVisit = async () => {
        if (!clinic) return;
        await updateFirestoreAppointment(clinic, appointmentId, {
            screening: formData,
            concent: isChecked,
        });
        return;
    };
    // const updateVisit = async () => {
    //     if (!settings?.webDocIntegration) {
    //         await updateFirebaseVisit();
    //         return;
    //     }
    //     const res = await updateVisitService(
    //         appointment.visitId,
    //         appointment.staffId,
    //         appointment.surveyFields,
    //         formData
    //     );
    //     if (res.error) {
    //         if (res.message.error.code === 1006) {
    //             await updateVisitService(
    //                 appointment.visitId,
    //                 appointment.staffId,
    //                 appointment.surveyFields,
    //                 formData
    //             );
    //             await updateFirebaseVisit();
    //         }
    //     }
    //     await updateFirebaseVisit();
    // };
    const submitSurveyForm = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!settings) return;
        updateLoading(true);
        await updateFirebaseVisit();
        updateLoading(false);
        navigate('/survey-successful');
    };

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>, title: string) => {
        if (title === 'checkbox') {
            updateIsChecked(!isChecked);
            return;
        }
        setFormData({ ...formData, [title]: e.target.value });
    };

    const screeningFormFields = settings?.screeningFields;

    return (
        <>
            {isLoading ? (
                <Loading loading={isLoading} />
            ) : (
                <Wrapper>
                    <h1 className={styles.surveyTitleText}>{t('survey_text')}</h1>
                    {screeningFormFields && (
                        <SurveyInputForm
                            submitSurveyForm={submitSurveyForm}
                            onInputChange={onInputChange}
                            screeningFields={screeningFormFields as ScreeningFields[]}
                            formData={formData}
                            checked={isChecked}
                        />
                    )}
                </Wrapper>
            )}
        </>
    );
};
export default Survey;
