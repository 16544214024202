import React, { FC } from 'react';
import ContentLoader from 'react-content-loader';
import useWindowSize from 'hooks/useWindowSize';

type PROPS = {
    children: React.ReactNode;
};
export const Skeleton: FC<PROPS> = ({ children }) => {
    const { isMobile } = useWindowSize();

    return (
        <ContentLoader
            speed={2}
            width={isMobile ? 360 : 450}
            height={700}
            viewBox="0 0 450 700"
            backgroundColor="#F8F8F8"
            foregroundColor="#F2F2F2"
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '20px',
            }}
        >
            {children}
        </ContentLoader>
    );
};
