/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styles from './checkinSuccessful.module.scss';
import noShoes from '../../assets/no-shoes.png';
import handSanitizer from '../../assets/handSanitizer.png';
import { useSettings } from 'hooks/SettingsContext';
import { useTranslation } from 'react-i18next';
import useWindowSize from 'hooks/useWindowSize';
import { toDataURL } from 'qrcode';
import { useAuth } from 'hooks/AuthContext';
// import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { BookingType, CaregiverType } from 'types';
import { Wrapper } from 'components/Wrapper';
// import Loading from 'components/Loading';
import { Box } from '../../components/Box';
import { Card } from '../../components/Card';
import { Button } from '../../components/Button';
import CurvedArrow from '../../assets/curved-arrow.png';
import { db } from 'firebase-service';
import { collection, doc, getDoc } from 'firebase/firestore';
import { SuccessCard } from 'features/SuccessCard';
import { ButtonContainer } from 'features/ButtonContainer';
import { Booking } from 'features/Booking';

type Location = {
    booking: BookingType;
    nextBooking: BookingType;
    appointmentId: string;
};
const CheckinSuccessful: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { settings } = useSettings();
    const { isMobile } = useWindowSize();
    const { clinic } = useAuth();
    const [qrCodeImg, setQrCodeImg] = useState<string>();
    // const [surveyComplete, setSurveyComplete] = useState(true);
    // const [loading, updateLoading] = useState(true);
    const { appointmentId } = useParams();
    const location = useLocation();
    const { booking, nextBooking } = location.state as Location;
    const [caregiver, setCaregiver] = useState<CaregiverType>();
    const gradient = !settings?.config.screening;
    useEffect(() => {
        if (!clinic) return undefined;
        const getCaregiver = async () => {
            const docRef = doc(
                collection(db, 'clinicSettings', clinic, 'caregivers'),
                booking?.caregiver.identifier
            );
            const docSnap = await getDoc(docRef);
            const data = docSnap.data();
            if (data) {
                setCaregiver(data as CaregiverType);
            }
        };
        getCaregiver();
        return () => {
            getCaregiver();
        };
    }, [booking]);

    useEffect(() => {
        const opts = {
            quality: 0.3,
            margin: 1,
            color: {
                dark: '#000000',
                light: '#F8F8F8',
            },
        };
        const getQrCode = async (appointmentId: string) => {
            const nextPage = '/survey';
            const qrCode = await toDataURL(
                `${window.location.origin}/qr/${clinic}${nextPage}/${appointmentId}`,
                opts
            );
            setQrCodeImg(qrCode);
        };
        if (!appointmentId) return;
        getQrCode(appointmentId);
    }, [appointmentId, clinic, settings]);
    // eslint-disable-next-line no-console

    // useEffect(() => {
    //     const isSurveyComplete = async (appointmentId: string) => {
    //         const db = getFirestore();
    //         const docRef = doc(db, 'appointments', appointmentId);
    //         const docData = await getDoc(docRef);
    //         const data = docData.data() as Appointment;
    //         setSurveyComplete(!!data.survey);
    //         updateLoading(false);
    //     };
    //     if (!appointmentId) return;
    //     isSurveyComplete(appointmentId);
    // }, [appointmentId, clinic, settings]);
    // eslint-disable-next-line no-console

    const infoIcons = settings?.config.isChildrenClinic
        ? [
              { img: noShoes, translationKey: 'check_in_successful_step_1' },
              { img: handSanitizer, translationKey: 'check_in_successful_step_2' },
          ]
        : [{ img: handSanitizer, translationKey: 'check_in_successful_step_2' }];

    return (
        <Wrapper gradient={gradient}>
            <Card>
                <SuccessCard
                    booking={booking}
                    caregiver={caregiver}
                    showButton
                    transparent={gradient}
                />
                <div className={styles.row}>
                    {!settings?.config.screening && nextBooking && (
                        <div>
                            <p className={styles.steps_title}>{t('upcoming_booking')}</p>
                            <Booking
                                light
                                booking={nextBooking}
                                clinicName={settings && settings.config.clinicName}
                                clinicId={clinic && clinic}
                                selectBooking={() => undefined}
                            />
                        </div>
                    )}
                    <div>
                        <p
                            className={
                                settings?.config.screening ? styles.qr_title : styles.steps_title
                            }
                        >
                            {settings?.config.screening && !isMobile
                                ? t('before_appointment')
                                : t('survey_successful_steps')}
                        </p>
                        <Box>
                            <div className={styles.qr_container}>
                                <div className={styles.icons_row}>
                                    {settings?.config.screening ? (
                                        <div className={styles.icons_wrapper}>
                                            <img
                                                src={CurvedArrow}
                                                alt=""
                                                className={styles.curved_arrow}
                                            />
                                            <div className={styles.icons_container}>
                                                <img
                                                    className={styles.icon_image}
                                                    src={qrCodeImg}
                                                    alt=""
                                                />
                                            </div>
                                            <p style={{ color: 'red' }}>
                                                {t('survey_qr_red_text')}
                                            </p>
                                        </div>
                                    ) : (
                                        infoIcons.map((icon, index) => (
                                            <div key={index} className={styles.icons_wrapper}>
                                                <div className={styles.icons_container}>
                                                    <img
                                                        className={styles.icon_image}
                                                        src={icon.img}
                                                        alt=""
                                                    />
                                                </div>
                                                <p style={{ color: '#ffffff' }}>
                                                    {t(icon.translationKey)}
                                                </p>
                                            </div>
                                        ))
                                    )}
                                </div>
                            </div>
                        </Box>
                    </div>
                </div>
                <ButtonContainer column>
                    <Button
                        transparent={gradient}
                        onClick={() => {
                            if (!settings) return;
                            navigate('/');
                        }}
                    >
                        {t('check_in_successful_button_new_checkin')}
                    </Button>
                </ButtonContainer>
            </Card>
        </Wrapper>
    );
};

export default CheckinSuccessful;
