/* eslint-disable max-lines */
import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styles from './checkout.module.scss';
import { useSettings } from 'hooks/SettingsContext';
import { useTranslation } from 'react-i18next';
import useWindowSize from 'hooks/useWindowSize';
import { Card } from 'components/Card';
import { Button } from 'components/Button';
import { createSwishPaymentService } from 'services/swish.service';
import { swishCallbackUrl } from 'config/endPoints';
import Loading from 'features/Loading';
import SwishLogo from '../../assets/swish-logo.svg';
import KlarnaLogo from '../../assets/klarna.png';
import FreeCardLogo from '../../assets/freecard.png';
import { initiateKlarnaPayment } from 'services/klarna.payment.service';
import { useParams } from 'react-router';
import { Wrapper } from 'components/Wrapper';
import { CheckIcon } from 'components/icons/check';
import { updateInvoiceService } from 'services/invoice.service';
import { sendInvoiceErrorNotification } from '../../services/notification.service';
import { db, updateFirestoreAppointment } from 'firebase-service';
import { Box } from '../../components/Box';
import { Skeleton } from '../../components/Skeleton/skeleton.component';
import { doc, getFirestore, onSnapshot, collection, getDoc } from 'firebase/firestore';
import { useAuth } from 'hooks/AuthContext';
import { SuccessCard } from 'features/SuccessCard';
import { ButtonContainer } from 'features/ButtonContainer';
import { CaregiverType } from 'types';

const Checkout: FC = () => {
    const { i18n } = useTranslation();
    const { clinic } = useAuth();
    const [loading, updateLoading] = useState(false);
    const [error, updateError] = useState(false);
    const [success, updateSuccess] = useState(false);
    const [errorMessage, updateErrorMessage] = useState('');
    const [caregiver, setCaregiver] = useState<CaregiverType>();
    const [selectedPaymentMethod, updateSelectedPaymentMethod] = useState('swish');
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { settings } = useSettings();
    const { isMobile } = useWindowSize();
    const { status } = useParams();
    const visitData = JSON.parse(localStorage.getItem('visitData') as string) ?? '';
    const { invoiceData, appointmentId, booking, nextBooking } = visitData;
    const invoiceId = settings?.webDocIntegration ? invoiceData[0].invoiceId : '';
    const invoiceNumber = settings?.webDocIntegration ? invoiceData[0].invoiceNumber : '';
    const netAmount = settings?.webDocIntegration ? invoiceData[0].netAmount : invoiceData;
    const nextPage = isMobile && settings?.config.screening ? '/survey/' : '/checkin-successful/';
    const [searchParams] = useSearchParams();
    const orderId = searchParams.get('order_id');
    useEffect(() => {
        const lang = localStorage.getItem('lang');
        if (lang !== null) {
            i18n.changeLanguage(lang);
        }
    }, []);

    const updateInvoiceStatus = async () => {
        if (!settings) return;
        const res = await updateInvoiceService(settings.webDocClinicId, invoiceId, netAmount);

        if (res.error) {
            await sendInvoiceErrorNotification(
                invoiceNumber,
                settings.config.email,
                t('invoice_failure_notification'),
                t('invoice_failure_subject')
            );
        }
    };
    const updatePaymentStatus = async () => {
        if (!clinic) return;
        await updateFirestoreAppointment(clinic, appointmentId, { arrived: 2, invoiceNumber });
        if (settings?.webDocIntegration) {
            await updateInvoiceStatus();
        }
        navigate(`${nextPage}${appointmentId}`, {
            state: {
                booking,
                nextBooking,
            },
        });
    };
    useEffect(() => {
        if (!clinic) return undefined;
        const getCaregiver = async () => {
            const docRef = doc(
                collection(db, 'clinicSettings', clinic, 'caregivers'),
                booking?.caregiver.identifier
            );
            const docSnap = await getDoc(docRef);
            const data = docSnap.data();
            if (data) {
                setCaregiver(data as CaregiverType);
            }
        };
        getCaregiver();
        return () => {
            getCaregiver();
        };
    }, []);
    useEffect(() => {
        if (!orderId) return undefined;
        const db = getFirestore();
        const subscribeKlarnaPayment = onSnapshot(
            doc(db, 'klarnaPayments', orderId),
            async (doc) => {
                const paymentRequest = doc.data();
                if (paymentRequest?.capture.captured_amount === +netAmount) {
                    updateSuccess(true);
                }
            }
        );
        return () => subscribeKlarnaPayment();
    }, [orderId]);

    useEffect(() => {
        if (success) {
            updatePaymentStatus();
        }
    }, [settings, success]);

    const updatePaymentMethod = (option: string) => {
        updateSelectedPaymentMethod(option);
    };

    const body = {
        callbackUrl: swishCallbackUrl,
        payeeAlias: settings?.swishNumber as string,
        currency: 'SEK',
        amount: netAmount.toString(),
    };

    const startPayment = async () => {
        updateLoading(!loading);
        if (!settings) return;
        if (selectedPaymentMethod === 'swish') {
            const response = await createSwishPaymentService(body);
            if (response.status === 200 && response.data.id) {
                const { id, token, location }: { [key: string]: string } = response.data;
                localStorage.setItem('swishData', JSON.stringify({ id, token, location }));
                updateLoading(false);
                navigate('/payment', {
                    state: {
                        booking,
                        nextBooking,
                    },
                });
            }
            if (response.error) {
                updateError(true);
                updateErrorMessage(response.message);
            }
            return;
        }
        if (selectedPaymentMethod === 'klarna') {
            const response = await initiateKlarnaPayment(
                window.location.origin,
                netAmount,
                `${settings.config.clinicName} visit`
            );
            if (response.redirect_url) {
                window.location.href = response.redirect_url;
            }
            return;
        }
    };
    if (loading) {
        return (
            <Wrapper>
                <Skeleton>
                    <rect x="60" rx="10" ry="10" width="320" height="56" />
                    <rect x="60" y="70" rx="10" ry="10" width="320" height="56" />
                    <rect x="60" y="140" rx="10" ry="10" width="320" height="238" />
                    <rect y="400" x="140" rx="30" ry="30" width="162" height="83" />
                </Skeleton>
            </Wrapper>
        );
    }
    if (status === 'success') {
        return (
            <Wrapper>
                <Card>
                    <Box success>
                        <CheckIcon color={'#22C493'} />
                        <p className={styles.success_text}>{t('payment_successful')}</p>
                    </Box>
                </Card>
            </Wrapper>
        );
    }

    return (
        <>
            {loading ? (
                <Loading loading={loading} />
            ) : (
                <Wrapper>
                    <Card>
                        <div className={styles.payment_method_list}>
                            {status === 'pay' && (
                                <SuccessCard booking={booking} caregiver={caregiver} />
                            )}

                            {/* <h3>{t('payment_method')}</h3> */}
                            <h1>{t('checkout_page_title')}</h1>
                            {status !== 'pay' && status !== 'success' && (
                                <Box error>
                                    <h5>{t('payment_error_text')}</h5>
                                </Box>
                            )}
                            {error && (
                                <Box error>
                                    <h5>{errorMessage}</h5>
                                </Box>
                            )}
                            {/* <p>{netAmount} SEK</p> */}

                            {settings && settings.paymentMethods.includes('swish') ? (
                                <Box active={selectedPaymentMethod === 'swish'}>
                                    <div
                                        className={styles.payment_method_container}
                                        onClick={() => updatePaymentMethod('swish')}
                                    >
                                        <div className={styles.radio_logo_container}>
                                            <div className={styles.radio_button}>
                                                <input
                                                    type="radio"
                                                    readOnly
                                                    checked={selectedPaymentMethod === 'swish'}
                                                />
                                                <p className={styles.bold_text}>Swish</p>
                                                {selectedPaymentMethod === 'swish' && (
                                                    <p>{netAmount} SEK</p>
                                                )}
                                            </div>
                                            <div className={styles.logo_container}>
                                                <img src={SwishLogo} alt="" width="60px" />
                                            </div>
                                        </div>
                                        {selectedPaymentMethod === 'swish' && (
                                            <div className={styles.text_container}>
                                                <p>{t('swish_bankid_info_text')}</p>
                                            </div>
                                        )}
                                    </div>
                                </Box>
                            ) : null}
                            {settings && settings.paymentMethods.includes('klarna') ? (
                                <Box active={selectedPaymentMethod === 'klarna'}>
                                    <div
                                        onClick={() => updatePaymentMethod('klarna')}
                                        className={styles.payment_method_container}
                                    >
                                        <div className={styles.radio_logo_container}>
                                            <div className={styles.radio_button}>
                                                <input
                                                    type="radio"
                                                    checked={selectedPaymentMethod === 'klarna'}
                                                    readOnly
                                                />
                                                <p className={styles.bold_text}>Klarna</p>
                                                {selectedPaymentMethod === 'klarna' && (
                                                    <p>{netAmount} SEK</p>
                                                )}
                                            </div>
                                            <div className={styles.logo_container}>
                                                <img src={KlarnaLogo} alt="" width="50px" />
                                            </div>
                                        </div>
                                        {selectedPaymentMethod === 'klarna' && (
                                            <div className={styles.text_container}>
                                                <p>{t('klarna_description_text')}</p>
                                            </div>
                                        )}
                                    </div>
                                </Box>
                            ) : null}
                            <Box active={selectedPaymentMethod === 'freeCard'}>
                                <div
                                    onClick={() => updatePaymentMethod('freeCard')}
                                    className={styles.payment_method_container}
                                >
                                    <div className={styles.radio_logo_container}>
                                        <div className={styles.radio_button}>
                                            <input
                                                type="radio"
                                                checked={selectedPaymentMethod === 'freeCard'}
                                                readOnly
                                            />
                                            <p className={styles.bold_text}>Frikort</p>
                                        </div>
                                        <div className={styles.logo_container}>
                                            <img src={FreeCardLogo} alt="" width="50px" />
                                        </div>
                                    </div>
                                    <div className={styles.text_container}>
                                        <p>{t('freeCard_description_text')}</p>
                                    </div>
                                </div>
                            </Box>
                        </div>
                        <ButtonContainer>
                            {selectedPaymentMethod !== 'freeCard' ? (
                                <Button onClick={startPayment}>{t('pay_button_text')}</Button>
                            ) : (
                                <Button
                                    onClick={() => {
                                        if (!settings) return;
                                        navigate(`${nextPage}${appointmentId}`, {
                                            state: {
                                                booking,
                                            },
                                        });
                                    }}
                                >
                                    Klar
                                </Button>
                            )}
                            <Button
                                onClick={() => {
                                    if (!settings) return;
                                    navigate(`${nextPage}${appointmentId}`, {
                                        state: {
                                            booking,
                                            nextBooking,
                                        },
                                    });
                                }}
                                transparent
                            >
                                {t('cancel_button_text')}
                            </Button>
                        </ButtonContainer>
                    </Card>
                </Wrapper>
            )}
        </>
    );
};
export default Checkout;
