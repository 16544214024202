import React, { ChangeEvent, FC } from 'react';
import styles from './surveyInput.module.scss';

type SurveyInputProps = {
    labelText: string;
    value: string;
    placeholder: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    type?: string;
};

const SurveyInput: FC<SurveyInputProps> = ({ labelText, value, placeholder, onChange, type }) => {
    return (
        <div className={styles.surveyInputContainer}>
            <p className={styles.label_text}>{labelText}:</p>
            <input
                className={styles.surveyNumberInput}
                value={value}
                placeholder={placeholder}
                type={type}
                onChange={onChange}
            />
        </div>
    );
};

export default SurveyInput;
