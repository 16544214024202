/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';

export const sendInvoiceErrorNotification = async (
    invoiceNumber: string,
    toEmail: string,
    message: string,
    subject: string
): Promise<any> => {
    const body = JSON.stringify({
        invoiceNumber,
        toEmail,
        message,
        subject,
    });

    try {
        const response = await axios.post('/notification/invoiceupdatefailure', body, {
            headers: {
                'content-type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return error;
        }
        return { error: 'error' };
    }
};
