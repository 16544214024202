/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import moment from 'moment';
import { decrypt } from '../crypto';

export const getStartDate = () => {
    const today = new Date();
    return moment(today).format('YYYY-MM-DD');
};

export const getEndDate = (days: number) => {
    return moment().add(days, 'days').format('YYYY-MM-DD');
};

export const getAge = (securityNumber: string) => {
    if (securityNumber?.length === 11) {
        const dob = securityNumber?.slice(0, 6);
        return moment().diff(dob, 'years');
    }
    const dob = securityNumber?.slice(0, 8);
    return moment().diff(dob, 'years');
};

export const getEncryptedAge = (cipherText: string) => {
    const securityNumber = decrypt(cipherText);
    if (securityNumber?.length === 11) {
        const dob = securityNumber?.slice(0, 6);
        return moment().diff(dob, 'years');
    }
    const dob = securityNumber?.slice(0, 8);
    return moment().diff(dob, 'years');
};
