import { FC } from 'react';
import styles from './loading.module.scss';
import FadeLoader from 'react-spinners/FadeLoader';

interface Props {
    loading: boolean;
}

const Loading: FC<Props> = ({ loading }) => {
    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <FadeLoader
                    color={'#22BBC4'}
                    loading={loading}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            </div>
        </div>
    );
};

export default Loading;
