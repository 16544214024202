import React, { FC, ReactNode } from 'react';
import styles from './buttonContainer.module.scss';

interface PropsType {
    column?: boolean;
    children: ReactNode;
}

export const ButtonContainer: FC<PropsType> = ({ column, children }) => {
    return <div className={column ? styles.container_col : styles.container}>{children}</div>;
};
