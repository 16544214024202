import React, { FC } from 'react';
import styles from './exclamationtriangle.module.scss';

type PROPS = {
    sm?: boolean;
};
const ExclamationTriangle: FC<PROPS> = ({ sm }) => {
    return (
        <div className={styles.header}>
            <div className={sm ? styles.triangle_sm : styles.triangle} />
            <span className={sm ? styles.exclamationMark_sm : styles.exclamationMark}>!</span>
        </div>
    );
};

export default ExclamationTriangle;
