/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { getStartDate, getEndDate } from 'helpers/date.helper';
import { axiosClient } from './webdocAxiosClient';

export const getBookingService = async (
    clinicId: string | undefined,
    personalNumber: string,
    bookingsPeriod: number
): Promise<any> => {
    const startDate = getStartDate();
    const endDate = getEndDate(bookingsPeriod);
    const body = {
        clinicId,
        personalNumber,
        startDate,
        endDate,
        baseURL: process.env.REACT_APP_API_URL,
    };
    try {
        const response = await axiosClient.post('/visits/booking', body);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            return error.response.data;
        }
        return { error: true, message: 'Unexpected error' };
    }
};
