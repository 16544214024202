import { FC } from 'react';
import styles from './header.module.scss';
import { useSettings } from 'hooks/SettingsContext';
import { useNavigate } from 'react-router-dom';

const Header: FC = () => {
    const navigate = useNavigate();
    const { settings } = useSettings();
    return (
        <div className={styles.wrapper} onClick={() => navigate('/')}>
            {settings?.config.logo && (
                <img src={settings?.config.logo} alt="logo" className={styles.logo} />
            )}
        </div>
    );
};
export default Header;
