import { FC, ReactNode } from 'react';
import styles from './button.module.scss';

type Props = {
    isBackgroundRed?: boolean;
    onClick: () => void;
    children: ReactNode;
    disabled?: boolean;
    transparent?: boolean;
};

export const Button: FC<Props> = ({ disabled, onClick, transparent, children }) => {
    if (transparent) {
        return (
            <button disabled={disabled} className={styles.transparent} onClick={onClick}>
                <p className={styles.gradient_text}>{children}</p>
            </button>
        );
    }
    return (
        <button disabled={disabled} className={styles.button} onClick={onClick}>
            <p className={styles.text}>{children}</p>
        </button>
    );
};
