/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable indent */
import { useEffect, useState } from 'react';
import { Booking } from 'features/Booking';
import { useSettings } from 'hooks/SettingsContext';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'hooks/AuthContext';
import { useNavigate } from 'react-router-dom';
import { analytics, checkInPatient } from 'firebase-service';
import { registerVisitService } from 'services/visit.service';
// import { getRecordTemplateDataService } from 'services/template.service';
import { useLocation } from 'react-router';
import { createInvoiceService } from 'services/invoice.service';
import { Card } from 'components/Card';
import { Wrapper } from 'components/Wrapper';
import { Button } from 'components/Button';
import useWindowSize from 'hooks/useWindowSize';
import ExclamationTriangle from '../../components/icons/ExclamationTriangle/exclamationTriangle';
import { Skeleton } from '../../components/Skeleton/skeleton.component';
import { Box } from '../../components/Box';
import { BookingType } from 'types';
import styles from './bookings.module.scss';
import { collection, doc, getFirestore, updateDoc } from 'firebase/firestore';
import { logEvent } from 'firebase/analytics';
import { getAge, getEncryptedAge } from 'helpers/date.helper';
import { ButtonContainer } from 'features/ButtonContainer';

type Location = {
    bookings: BookingType[];
    securityNumber: string;
};

const Bookings = () => {
    const { clinic } = useAuth();
    const { settings } = useSettings();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, updateLoading] = useState(false);
    const { bookings, securityNumber } = location.state as Location;
    const { todayBookings, checkInAbleBookings, upComingBookings } = bookings as any;
    const nextBooking = todayBookings[1] ?? upComingBookings[0];
    const [selectedBooking, setSelectedBooking] = useState<BookingType>(checkInAbleBookings[0]);
    const { isMobile } = useWindowSize();
    document.body.dir = i18n.dir();

    const updateSelectedBooking = (booking: BookingType) => {
        setSelectedBooking(booking);
    };

    const firebaseCheckIn = async (booking: BookingType) => {
        if (!clinic || !settings || !booking) return;
        updateLoading(true);
        const db = getFirestore();
        const appointmentRef = doc(
            collection(db, 'clinicSettings', clinic, 'appointments'),
            booking.id
        );
        await updateDoc(appointmentRef, {
            arrived: 1,
            status: 'checked in',
        });
        logEvent(analytics, 'check-in-successful', { clinic });
        const appointmentId = appointmentRef.id;
        const age = getEncryptedAge(booking.patient.personalNumber);

        if (
            age > 18 &&
            age < 85 &&
            settings.hasPaymentsIntegration &&
            settings?.config.acceptPayment &&
            booking.payments.length &&
            booking.payments[0].price > '0'
        ) {
            localStorage.setItem(
                'visitData',
                JSON.stringify({
                    invoiceData: booking.payments[0].price,
                    appointmentId,
                    booking,
                    nextBooking,
                })
            );
            navigate('/checkout/pay');
            return;
        }
        const nextPage = isMobile && settings.config.screening ? '/survey' : '/checkin-successful';
        navigate(`${nextPage}/${appointmentId}`, {
            state: {
                appointmentId,
                booking,
                nextBooking,
            },
        });
    };

    const webdocCheckIn = async (booking: BookingType) => {
        if (!clinic || !settings || !booking) return;
        updateLoading(true);
        const visitData = await registerVisitService(booking.id);
        const patientName = [booking.patient.firstName, booking.patient.lastName];
        const appointmentId = await checkInPatient(
            securityNumber,
            clinic,
            booking.id,
            booking.caregiver.id,
            visitData.data.id,
            patientName
        );
        logEvent(analytics, 'check-in-successful', { clinic });
        const age = getAge(booking.patient.personalNumber);
        if (
            age > 18 &&
            age < 85 &&
            settings.hasPaymentsIntegration &&
            settings.config.acceptPayment &&
            visitData.data.payments.length &&
            visitData.data.payments[0].price > 0
        ) {
            const invoiceData = await createInvoiceService(
                settings.webDocClinicId,
                visitData.data.id,
                visitData.data.payments[0].price
            );
            localStorage.setItem(
                'visitData',
                JSON.stringify({
                    invoiceData: invoiceData.data,
                    appointmentId,
                    userId: booking.caregiver.id,
                    visitId: visitData.data.id,
                    booking,
                })
            );
            navigate('/checkout/pay');
            return;
        }
        localStorage.setItem(
            'visitData',
            JSON.stringify({
                appointmentId,
                userId: booking.caregiver.id,
                visitId: visitData.data.id,
                booking,
            })
        );
        const nextPage = isMobile && settings.config.screening ? '/survey' : '/checkin-successful';
        navigate(`${nextPage}/${appointmentId}`, {
            state: {
                appointmentId,
                booking,
            },
        });
    };
    const checkIn = () => {
        if (!selectedBooking) return undefined;
        if (settings?.careLabsBookingIntegration) {
            return firebaseCheckIn(selectedBooking);
        }
        return webdocCheckIn(selectedBooking);
    };
    if (loading) {
        return (
            <>
                <Wrapper>
                    <Card>
                        <Skeleton>
                            <rect x="20" rx="10" ry="10" width="420" height="50" />
                            <rect x="20" y="65" rx="10" ry="10" width="420" height="20" />
                            <rect x="20" y="110" rx="10" ry="10" width="420" height="250" />
                        </Skeleton>
                    </Card>
                </Wrapper>
            </>
        );
    }
    return (
        <Wrapper>
            <h1>{t('booking_page_title')}</h1>
            <Card>
                {checkInAbleBookings.length > 0 ? (
                    <p className={styles.checkin_instruction_text}>
                        {t('select_booking_to_checkin')}
                    </p>
                ) : null}

                {checkInAbleBookings.length > 0
                    ? checkInAbleBookings.slice(0, 2).map((booking: BookingType) => {
                          return (
                              <Booking
                                  clinicName={settings && settings.config.clinicName}
                                  clinicId={clinic && clinic}
                                  key={booking.id}
                                  booking={booking}
                                  selectBooking={updateSelectedBooking}
                                  selected={booking.id === selectedBooking?.id}
                                  active
                                  light={false}
                              />
                          );
                      })
                    : null}
                {todayBookings.length > 0 && checkInAbleBookings.length < 1 ? (
                    <Box grey>
                        <div className={styles.content_container}>
                            <ExclamationTriangle sm />
                            <p style={{ textAlign: 'center' }}>{t('late_arrival')}</p>
                        </div>
                    </Box>
                ) : null}
                {todayBookings.length < 1 ? (
                    <Box grey>
                        <div className={styles.content_container}>
                            <ExclamationTriangle sm />
                            <p style={{ textAlign: 'center' }}>{t('no_booking_today')}</p>
                        </div>
                    </Box>
                ) : null}
                {settings?.config.showUpcomingBookings &&
                    upComingBookings.length > 0 &&
                    checkInAbleBookings.length <= 1 && (
                        <>
                            <h4>{t('upcoming_booking')}</h4>
                            {upComingBookings.length > 0 ? (
                                <Booking
                                    clinicName={settings && settings.config.clinicName}
                                    clinicId={clinic && clinic}
                                    key={upComingBookings[0].id}
                                    booking={upComingBookings[0]}
                                    active={false}
                                    selected={false}
                                    selectBooking={() => null}
                                    light={false}
                                />
                            ) : null}
                        </>
                    )}
                <ButtonContainer>
                    {!checkInAbleBookings.length ? (
                        <Button onClick={() => navigate('/check-in')}>{t('back_home_link')}</Button>
                    ) : (
                        <Button onClick={checkIn}>{t('check_in_button')}</Button>
                    )}
                </ButtonContainer>
            </Card>
        </Wrapper>
    );
};

export default Bookings;
