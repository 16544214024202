import { SettingsProvider } from 'hooks/SettingsContext';
import { BrowserRouter as Router } from 'react-router-dom';
import './i18n';
import App from './App';
import { AuthProvider } from 'hooks/AuthContext/authContext.hook';
import './index.scss';
import { TimerProvider } from 'hooks/TimerContext';
import { createRoot } from 'react-dom/client';

const container = document.getElementById('hyperstack-ehealth-client') as Element;
const root = createRoot(container);

root.render(
    <Router>
        <AuthProvider>
            <SettingsProvider>
                <TimerProvider>
                    <App />
                </TimerProvider>
            </SettingsProvider>
        </AuthProvider>
    </Router>
);
