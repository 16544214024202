/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import { axiosClient } from './webdocAxiosClient';
import { ScreeningFields } from 'types';

export const registerVisitService = async (bookingId: string): Promise<any> => {
    const body = {
        bookingId,
        baseURL: process.env.REACT_APP_API_URL,
    };
    try {
        const response = await axiosClient.post('/visits/register', body);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            return error.response.data;
        }

        return { error: true, message: 'Unexpected error' };
    }
};
export const updateVisitService = async (
    visitId: string,
    userId: string,
    surveyData: ScreeningFields[],
    formData: { [key: string]: string | number | undefined }
): Promise<any> => {
    const userData =
        surveyData &&
        surveyData.map((keyWord) => {
            const inputValue = Object.entries(formData).find(([key]) => key === keyWord.title);
            return {
                id: keyWord.id,
                value: inputValue && inputValue[1],
            };
        });

    const data = JSON.stringify({
        userId,
        keywords: userData,
    });

    try {
        const response = await axiosClient.patch(`/v1/visits/${visitId}/records`, data);
        return { status: response.status, data: response.data };
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            return { error: true, message: error.response.data };
        }

        return { error: true, message: 'Unexpected error' };
    }
};
