import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormData, ScreeningFields } from 'types';
import styles from './surveyInputForm.module.scss';
import SurveyInput from 'features/SurveyInput';

type PropsType = {
    submitSurveyForm: (e: React.FormEvent<HTMLFormElement>) => Promise<void>;
    onInputChange: (e: React.ChangeEvent<HTMLInputElement>, title: string) => void;
    screeningFields: ScreeningFields[];
    formData: FormData;
    checked: boolean;
};

const SurveyInputForm: FC<PropsType> = ({
    submitSurveyForm,
    onInputChange,
    screeningFields,
    formData,
    checked,
}) => {
    const { t } = useTranslation();
    return (
        <form onSubmit={submitSurveyForm} className={styles.surveyInputContainer}>
            {screeningFields.map(({ id, title, information, type }: ScreeningFields) => (
                <SurveyInput
                    key={id}
                    labelText={title}
                    type={type ?? ''}
                    placeholder={information ?? title}
                    value={formData[title] ?? ''}
                    onChange={(e) => onInputChange(e, title)}
                />
            ))}
            <div className={styles.checkbox}>
                <input
                    style={{ width: '50px' }}
                    type="checkbox"
                    checked={checked}
                    onChange={(e) => onInputChange(e, 'checkbox')}
                />
                <p>{t('consent_text')}</p>
            </div>

            <button
                disabled={Object.entries(formData ?? {}).some((entry) => !entry[1])}
                type="submit"
                className={styles.surveyButton}
            >
                {t('send')}
            </button>
        </form>
    );
};

export default SurveyInputForm;
