export const getCurrentDate = (locale: string): string =>
    new Intl.DateTimeFormat(locale, {
        month: 'long',
        day: 'numeric',
    }).format(new Date());

export const getCurrentTime = (locale: string): string =>
    new Intl.DateTimeFormat(locale, {
        hour: 'numeric',
        minute: 'numeric',
    }).format(new Date());
